:root {
    --mainColor: #1C252C;
    --mainColorHover: #131b22;
    --mainContent: white;
    --headerContent: #e93956;
    --mainColorDarker: #161c21;
    --mainColorLighter: rgb(34, 42, 48);

    --bannerHeight: 440px;
    --bannerHeightMobile: 600px;
    --headerBg: #1C252C;
    --footerBg: #1C252C;
    --footerDarker: #161c21;
    
    --secondColor: #e93956;
    --secondColorHover: #ff5470;
    --secondContent: white;

    --fadedMainColor: rgba(255, 86, 155, 0.3);
    --veryFadedMainColor: rgba(255, 86, 155, 0.08);

    --transparentWhite: rgba(255, 255, 255, 0.5);

    --highlightGreen: #00f58f;
    --highlightGreenHover: #10ff9b;

    --grayedText: #888888;
    --redText: red;
    --greenText: #00a130;
    --blueText: #0059ff;
    --yellowText: #ffcc00;
    --inputPlaceholderColor: #9E9E9E;

    --grayIcon: #b6b6b6;

    --veryVeryLightGray: #fafafa;
    --veryLightGray: #f5f5f5;
    --lightGray: #ececec;
    --focusBg: #eeeeee;
    --textColor: #333;
    --darkTextColor: #1f1f1f;
    --defaultTextSize: 14px;
    --headerHeight: 60px;
    --headerHeightMobile: 60px;
    /* --headerHeightMobile: 40px; */

    --giantButtonGradient: linear-gradient(to top right, #be122f, #FF5773);
    --giantButtonGradientHover: linear-gradient(to top right, #8e0219, #be122f);
    --giantButtonGradientContent: white;

    --hoverTranslateY: -4px;
    --hoverScale: 1.05;
    --hoverScaleLarge: 1.1;
    --hoverScaleSmall: 1.025;

    --productTileWidth: 300px;
    --productTileSmallWidth: 236px;
    --productTileWidthMobile: 100%;
    --productTileHeight: 420px;
    --productTileSmallHeight: 360px;
    --productTileHeightMobile: 480px;
    --productTileMargin: 10px;
    --productTileMarginMobile: 0px;

    --defaultElementHeight: 36px;

    --mediumRowGap: 14px;
    --smallRowGap: 10px;
    --tinyRowGap: 6px;
    --largeRowGap: 20px;
    
    --mediumPadding: 14px;

    --tinyBorderRadius: 3px;
    --smallBorderRadius: 4px;
    --mediumBorderRadius: 8px;
    --largeBorderRadius: 12px;

    --defaultMarginBottom: 40px;
    /* --defaultMarginBottom: 16vh; */

    --catTileWidth: 216px;
    --catTileHeight: 216px;
    --catTileMargin: 10px;

    --defaultDialogWidth: 640px;
    --defaultDialogHeight: 640px;

    --lightHighlightMark: #e4edea;

    /* --defaultCardShadow: 0 0 6px rgba(0, 0, 0, 0.05); */
}